.App {
  /* text-align: center; */
}

h2 {
  padding-left: 1rem !important;
}

p {
  padding-left: 1rem !important;
}
